import { Injectable } from '@angular/core';
import { IncentivePartnerMonitoringFilterOptions } from '@coin/shared/util-enums';
import { IncentivePartnerService, IncentivePartnerServiceQueryFieldValuesMonitoringRequestParams } from '@coin/shared/data-access';
import { IncentiveSupportComponentState } from './incentive-support.component.state';

@Injectable()
export class MonitoringFieldValuesService {
  constructor(
    private incentiveSupportComponentState: IncentiveSupportComponentState,
    private monitoringService: IncentivePartnerService
  ) {}

  public getFieldValues(page: number, size: number, targetProperty: IncentivePartnerMonitoringFilterOptions, searchText?: string) {
    const selectedSeason = this.incentiveSupportComponentState.getSelectedSeason();
    if (!selectedSeason) {
      throw new Error('Cannot get field values without selected season');
    }

    const mappedTargetProperty = IncentivePartnerMonitoringFilterOptions[targetProperty];
    const args: IncentivePartnerServiceQueryFieldValuesMonitoringRequestParams = {
      seasonId: selectedSeason.id,
      page: page + 1,
      size: size,
      targetProperty: this.mappedTargetPropertyToIndex(mappedTargetProperty)
    };
    args[`${mappedTargetProperty}`] = searchText;

    return this.monitoringService.queryFieldValuesMonitoring(args);
  }

  private mappedTargetPropertyToIndex(targetProperty: IncentivePartnerMonitoringFilterOptions): 0 | 1 | 5 {
    // TODO: Transform to enum in swagger for easier mapping?
    switch (targetProperty) {
      case IncentivePartnerMonitoringFilterOptions.GidContains:
        return 1;
      case IncentivePartnerMonitoringFilterOptions.Country:
        return 5;
      case IncentivePartnerMonitoringFilterOptions.AssignmentIdContains:
      default:
        return 0;
    }
  }
}
