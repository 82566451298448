import { Injectable } from '@angular/core';
import { IncentivePartnerMonitoringFilterOptions } from '@coin/shared/util-enums';
import { IncentivePartnerService } from '@coin/shared/data-access';
import { GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRequestParams } from '../generated';
import { IncentiveSupportComponentState } from './incentive-support.component.state';

@Injectable()
export class MonitoringFieldValuesService {
  constructor(
    private incentiveSupportComponentState: IncentiveSupportComponentState,
    private monitoringService: IncentivePartnerService
  ) {}

  public getFieldValues(page: number, size: number, targetProperty: IncentivePartnerMonitoringFilterOptions, searchText?: string) {
    const selectedSeason = this.incentiveSupportComponentState.getSelectedSeason();
    if (!selectedSeason) {
      throw new Error('Cannot get field values without selected season');
    }
    const mappedTargetProperty = IncentivePartnerMonitoringFilterOptions[targetProperty];
    const args: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRequestParams = {
      seasonId: selectedSeason.id,
      pagingPage: page + 1,
      pagingSize: size,
      queryTargetProperty: mappedTargetProperty
    };
    args[`query${mappedTargetProperty}`] = searchText;

    return this.monitoringService.queryFieldValuesMonitoring(args);
  }
}
