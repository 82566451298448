/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RestructuringReportingRestructuringRequestReportItemModel {
  id?: string;
  attributeId?: string | null;
  variableType?: RestructuringReportingRestructuringRequestReportItemModelVariableTypeEnum | null;
  value?: string | null;
  isEditable?: boolean;
  assignmentId?: string;
}
export enum RestructuringReportingRestructuringRequestReportItemModelVariableTypeEnum {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  Boolean = 'Boolean',
  Country = 'Country',
  Currency = 'Currency',
  Percentage = 'Percentage'
}
