<coin-v2-dialog-frame [header]="data?.dialogTitle | translate" (closeButtonClick)="close()">
  <div class="content">
    @for (section of data?.sections; track section) {
      <div class="section-container">
        <div class="section">
          @if (section?.title) {
            <h2>{{ section?.title | translate }}</h2>
          }
          @if (section.isEditable) {
            <quill-editor [formControl]="formGroup.controls[section.id]"></quill-editor>
          } @else if (section?.text) {
            <p [innerHTML]="section?.text | translate"></p>
          }
        </div>
        @if (section !== data?.sections.at(-1)) {
          <div class="divider"></div>
        }
      </div>
    }
  </div>

  @if (!isReadOnly) {
    <coin-v2-dialog-footer>
      <coin-v2-button type="secondary" [text]="'general.btnCancel' | translate" (click)="close()"></coin-v2-button>
      <coin-v2-button icon="save" type="primary" [disabled]="formGroup.pristine" [text]="'general.btnSave' | translate" (click)="save()"></coin-v2-button>
    </coin-v2-dialog-footer>
  }
</coin-v2-dialog-frame>
