import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { V2CardComponent } from '../card/v2-card.component';

@Component({
  selector: 'coin-v2-card-customer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './v2-card-customer.component.html',
  styleUrls: ['../card/v2-card.component.scss', './v2-card-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2CardCustomerComponent extends V2CardComponent implements OnInit {
  ngOnInit(): void {
    this.rounding = 'medium';
    this.padding = 'none';
    this.shadow = 'large';
  }
}
