/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RestructuringComponentsRestructuringViewCellModel {
  rowNumber?: number;
  isEditable?: boolean;
  cellType?: RestructuringComponentsRestructuringViewCellModelCellTypeEnum;
  content?: string | null;
  attributeId?: string | null;
  variableType?: RestructuringComponentsRestructuringViewCellModelVariableTypeEnum | null;
  isDropdown?: boolean | null;
  displayType?: RestructuringComponentsRestructuringViewCellModelDisplayTypeEnum;
  tooltip?: string | null;
}
export enum RestructuringComponentsRestructuringViewCellModelCellTypeEnum {
  Attribute = 'Attribute',
  Text = 'Text',
  Matrix = 'Matrix',
  Condition = 'Condition'
}
export enum RestructuringComponentsRestructuringViewCellModelVariableTypeEnum {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  Boolean = 'Boolean',
  Country = 'Country',
  Currency = 'Currency',
  Percentage = 'Percentage'
}
export enum RestructuringComponentsRestructuringViewCellModelDisplayTypeEnum {
  Default = 'Default',
  Result = 'Result',
  Invisible = 'Invisible'
}
