/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface IncentiveRecordsAdjustRecordLetterStateModel {
  letterType?: IncentiveRecordsAdjustRecordLetterStateModelLetterTypeEnum;
  state?: IncentiveRecordsAdjustRecordLetterStateModelStateEnum;
}
export enum IncentiveRecordsAdjustRecordLetterStateModelLetterTypeEnum {
  Other = 'Other',
  AdherenceLetter = 'AdherenceLetter',
  TargetAgreementForm = 'TargetAgreementForm',
  TargetAchievementForm = 'TargetAchievementForm',
  MeritAllocation = 'MeritAllocation',
  SpecialPaymentLetter = 'SpecialPaymentLetter',
  RestructuringCalculation = 'RestructuringCalculation',
  RestructuringLetter = 'RestructuringLetter'
}
export enum IncentiveRecordsAdjustRecordLetterStateModelStateEnum {
  None = 'None',
  Created = 'Created',
  InManagerReview = 'InManagerReview',
  InEmployeeReview = 'InEmployeeReview',
  Published = 'Published',
  ManagerRejected = 'ManagerRejected',
  EmployeeRejected = 'EmployeeRejected',
  NoLetterRequired = 'NoLetterRequired'
}
