<div class="container">
  <div class="header">
    <h3>You are logged in as a Guest</h3>
  </div>
  <div class="disclaimer">
    <div>
      <mat-icon>priority_high</mat-icon>
      <p class="bold">You are no Siemens Energy employee. Please contact the cutsomer support If that´s wrong. Otherwise
        start your
        journey as a guest.</p>
    </div>
  </div>
  <div class="content">
    <coin-v2-card styleVariant="large" padding="none">
      <div>
        <div class="left">
          <mat-icon>exit_to_app</mat-icon>
        </div>
        <h3>Start the COIN App for Customer as a Guest</h3>
      </div>
      <coin-action-button class="primary" (click)="close()">Start</coin-action-button>
    </coin-v2-card>
    <coin-v2-card styleVariant="large" padding="none">
      <div>
        <div class="left left--contrast">
          <mat-icon>exit_to_app</mat-icon>
        </div>
        <h3>Ask for permission to COIN App for Customer</h3>
      </div>
      <coin-action-button class="secondary">Customer Support</coin-action-button>
    </coin-v2-card>
  </div>
</div>
