/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel,
  HeadcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel,
  HeadcountOverviewReportsReportModel,
  SharedFieldValuesModel,
  SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel,
  SharedPageModelOfHeadcountOverviewTeamMemberAvailabilityAggregationsTeamMemberAvailabilityAggregationModel,
  SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel,
  SharedPageModelOfSystemString
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface HeadcountOverviewServiceGetDepartmentReportAnalyticsReportsRequestParams {
  /** The organisation of which a report should be returned of. */
  department: string;
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  headcountDisplayType: 0 | 1;
  showEmployees?: boolean;
  showNewRequests?: boolean;
}

export interface HeadcountOverviewServiceGetDetailsTeamMembersRequestParams {
  id: string;
  startDate: string;
  endDate: string;
}

export interface HeadcountOverviewServiceGetTeamReportAnalyticsReportsRequestParams {
  /** The organisation of which a report should be returned of. */
  department: string;
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  headcountDisplayType: 0 | 1;
  showEmployees?: boolean;
  showNewRequests?: boolean;
}

export interface HeadcountOverviewServicePageAnalyticsDepartmentCodesAnalyticsDepartmentCodesRequestParams {
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  search?: string;
  page?: number;
  size?: number;
}

export interface HeadcountOverviewServicePageTeamDepartmentCodesTeamDepartmentCodesRequestParams {
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  search?: string;
  page?: number;
  size?: number;
}

export interface HeadcountOverviewServiceQueryAggregatedDepartmentAvailabilitiesAvailabilityAggregationRequestParams {
  /** The organisation of which the departments should be returned of. */
  department: string;
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  headcountDisplayType: 0 | 1;
  showEmployees?: boolean;
  showNewRequests?: boolean;
  page?: number;
  size?: number;
}

export interface HeadcountOverviewServiceQueryAggregatedTeamMemberAvailabilitiesAvailabilityAggregationRequestParams {
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  /** The organisation of which the team members should be returned of. */
  departments?: Array<string>;
  showEmployees?: boolean;
  showNewRequests?: boolean;
  isAnalyticsView?: boolean;
  priorityEmployeeId?: string;
  priorityPositionRequestId?: string;
  /** The are code of the team member to filter for. */
  are?: Array<string>;
  /** The org code of the team member to filter for. */
  orgCode?: Array<string>;
  /** The org code the team member\&#39;s org code has to start with. */
  orgCodeStartsWith?: string;
  /** The business area of the team member. */
  businessArea?: Array<string>;
  /** The region of the team member. */
  region?: Array<string>;
  /** The country of the team member. */
  country?: Array<string>;
  /** The location of the team member. */
  location?: Array<string>;
  /** The country cost type of the team member. */
  countryCostType?: Array<'BestCost' | 'HighCost'>;
  page?: number;
  size?: number;
  orderBy?: 'Asc' | 'Desc';
  property?: string;
}

export interface HeadcountOverviewServiceQueryFieldValuesTeamMemberFieldValuesRequestParams {
  field: string;
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  search?: string;
  /** The organisation of which the team members should be returned of. */
  departments?: Array<string>;
  /** The are code of the team member to filter for. */
  are?: Array<string>;
  /** The org code of the team member to filter for. */
  orgCode?: Array<string>;
  /** The org code the team member\&#39;s org code has to start with. */
  orgCodeStartsWith?: string;
  /** The business area of the team member. */
  businessArea?: Array<string>;
  /** The region of the team member. */
  region?: Array<string>;
  /** The country of the team member. */
  country?: Array<string>;
  /** The location of the team member. */
  location?: Array<string>;
  /** The country cost type of the team member. */
  countryCostType?: Array<'BestCost' | 'HighCost'>;
  page?: number;
  size?: number;
}

export interface HeadcountOverviewServiceQueryTeamMembersRequestParams {
  /** The inclusive time at which to start aggregating headcounts. */
  startDate: string;
  /** The inclusive time at which to end aggregating headcounts. */
  endDate: string;
  search?: string;
  /** The department of which the team members should be returned of. */
  departments?: Array<string>;
  page?: number;
  size?: number;
  orderBy?: 'Asc' | 'Desc';
  property?: string;
}

export interface HeadcountOverviewServiceUpdateManyTeamMemberAvailabilitiesTeamMembersRequestParams {
  id: string;
  startDate: string;
  endDate: string;
  headcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel?: Array<HeadcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel>;
}

export interface HeadcountOverviewServiceUpdateTeamMemberAvailabilityTeamMembersRequestParams {
  id: string;
  startDate: string;
  endDate: string;
  headcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel?: HeadcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel;
}

@Injectable({
  providedIn: 'root'
})
export class HeadcountOverviewService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDepartmentReportAnalyticsReports(
    requestParameters?: HeadcountOverviewServiceGetDepartmentReportAnalyticsReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HeadcountOverviewReportsReportModel>;
  public getDepartmentReportAnalyticsReports(
    requestParameters?: HeadcountOverviewServiceGetDepartmentReportAnalyticsReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<HeadcountOverviewReportsReportModel>>;
  public getDepartmentReportAnalyticsReports(
    requestParameters?: HeadcountOverviewServiceGetDepartmentReportAnalyticsReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<HeadcountOverviewReportsReportModel>>;
  public getDepartmentReportAnalyticsReports(
    requestParameters?: HeadcountOverviewServiceGetDepartmentReportAnalyticsReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const department = requestParameters?.department;
    if (department === null || department === undefined) {
      throw new Error('Required parameter department was null or undefined when calling getDepartmentReportAnalyticsReports.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling getDepartmentReportAnalyticsReports.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling getDepartmentReportAnalyticsReports.');
    }
    const headcountDisplayType = requestParameters?.headcountDisplayType;
    if (headcountDisplayType === null || headcountDisplayType === undefined) {
      throw new Error('Required parameter headcountDisplayType was null or undefined when calling getDepartmentReportAnalyticsReports.');
    }
    const showEmployees = requestParameters?.showEmployees;
    const showNewRequests = requestParameters?.showNewRequests;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (department !== undefined && department !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>department, 'Department');
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (headcountDisplayType !== undefined && headcountDisplayType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>headcountDisplayType, 'HeadcountDisplayType');
    }
    if (showEmployees !== undefined && showEmployees !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>showEmployees, 'ShowEmployees');
    }
    if (showNewRequests !== undefined && showNewRequests !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>showNewRequests, 'ShowNewRequests');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/headcount/overview/analytics-reports/department`;
    return this.httpClient.request<HeadcountOverviewReportsReportModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDetailsTeamMembers(
    requestParameters?: HeadcountOverviewServiceGetDetailsTeamMembersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>;
  public getDetailsTeamMembers(
    requestParameters?: HeadcountOverviewServiceGetDetailsTeamMembersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public getDetailsTeamMembers(
    requestParameters?: HeadcountOverviewServiceGetDetailsTeamMembersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public getDetailsTeamMembers(
    requestParameters?: HeadcountOverviewServiceGetDetailsTeamMembersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getDetailsTeamMembers.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling getDetailsTeamMembers.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling getDetailsTeamMembers.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/headcount/customer/overview/team-members/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/details`;
    return this.httpClient.request<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTeamReportAnalyticsReports(
    requestParameters?: HeadcountOverviewServiceGetTeamReportAnalyticsReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HeadcountOverviewReportsReportModel>;
  public getTeamReportAnalyticsReports(
    requestParameters?: HeadcountOverviewServiceGetTeamReportAnalyticsReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<HeadcountOverviewReportsReportModel>>;
  public getTeamReportAnalyticsReports(
    requestParameters?: HeadcountOverviewServiceGetTeamReportAnalyticsReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<HeadcountOverviewReportsReportModel>>;
  public getTeamReportAnalyticsReports(
    requestParameters?: HeadcountOverviewServiceGetTeamReportAnalyticsReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const department = requestParameters?.department;
    if (department === null || department === undefined) {
      throw new Error('Required parameter department was null or undefined when calling getTeamReportAnalyticsReports.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling getTeamReportAnalyticsReports.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling getTeamReportAnalyticsReports.');
    }
    const headcountDisplayType = requestParameters?.headcountDisplayType;
    if (headcountDisplayType === null || headcountDisplayType === undefined) {
      throw new Error('Required parameter headcountDisplayType was null or undefined when calling getTeamReportAnalyticsReports.');
    }
    const showEmployees = requestParameters?.showEmployees;
    const showNewRequests = requestParameters?.showNewRequests;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (department !== undefined && department !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>department, 'Department');
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (headcountDisplayType !== undefined && headcountDisplayType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>headcountDisplayType, 'HeadcountDisplayType');
    }
    if (showEmployees !== undefined && showEmployees !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>showEmployees, 'ShowEmployees');
    }
    if (showNewRequests !== undefined && showNewRequests !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>showNewRequests, 'ShowNewRequests');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/headcount/overview/analytics-reports/team`;
    return this.httpClient.request<HeadcountOverviewReportsReportModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public isManagerOfManagersEmployees(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<boolean>;
  public isManagerOfManagersEmployees(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<boolean>>;
  public isManagerOfManagersEmployees(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<boolean>>;
  public isManagerOfManagersEmployees(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/headcount/overview/employees/is-manager-of-managers`;
    return this.httpClient.request<boolean>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pageAnalyticsDepartmentCodesAnalyticsDepartmentCodes(
    requestParameters?: HeadcountOverviewServicePageAnalyticsDepartmentCodesAnalyticsDepartmentCodesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public pageAnalyticsDepartmentCodesAnalyticsDepartmentCodes(
    requestParameters?: HeadcountOverviewServicePageAnalyticsDepartmentCodesAnalyticsDepartmentCodesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public pageAnalyticsDepartmentCodesAnalyticsDepartmentCodes(
    requestParameters?: HeadcountOverviewServicePageAnalyticsDepartmentCodesAnalyticsDepartmentCodesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public pageAnalyticsDepartmentCodesAnalyticsDepartmentCodes(
    requestParameters?: HeadcountOverviewServicePageAnalyticsDepartmentCodesAnalyticsDepartmentCodesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling pageAnalyticsDepartmentCodesAnalyticsDepartmentCodes.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling pageAnalyticsDepartmentCodesAnalyticsDepartmentCodes.');
    }
    const search = requestParameters?.search;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (search !== undefined && search !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/headcount/customer/overview/analytics-department-codes`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pageTeamDepartmentCodesTeamDepartmentCodes(
    requestParameters?: HeadcountOverviewServicePageTeamDepartmentCodesTeamDepartmentCodesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public pageTeamDepartmentCodesTeamDepartmentCodes(
    requestParameters?: HeadcountOverviewServicePageTeamDepartmentCodesTeamDepartmentCodesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public pageTeamDepartmentCodesTeamDepartmentCodes(
    requestParameters?: HeadcountOverviewServicePageTeamDepartmentCodesTeamDepartmentCodesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public pageTeamDepartmentCodesTeamDepartmentCodes(
    requestParameters?: HeadcountOverviewServicePageTeamDepartmentCodesTeamDepartmentCodesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling pageTeamDepartmentCodesTeamDepartmentCodes.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling pageTeamDepartmentCodesTeamDepartmentCodes.');
    }
    const search = requestParameters?.search;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (search !== undefined && search !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/headcount/customer/overview/team-department-codes`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryAggregatedDepartmentAvailabilitiesAvailabilityAggregation(
    requestParameters?: HeadcountOverviewServiceQueryAggregatedDepartmentAvailabilitiesAvailabilityAggregationRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel>;
  public queryAggregatedDepartmentAvailabilitiesAvailabilityAggregation(
    requestParameters?: HeadcountOverviewServiceQueryAggregatedDepartmentAvailabilitiesAvailabilityAggregationRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel>>;
  public queryAggregatedDepartmentAvailabilitiesAvailabilityAggregation(
    requestParameters?: HeadcountOverviewServiceQueryAggregatedDepartmentAvailabilitiesAvailabilityAggregationRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel>>;
  public queryAggregatedDepartmentAvailabilitiesAvailabilityAggregation(
    requestParameters?: HeadcountOverviewServiceQueryAggregatedDepartmentAvailabilitiesAvailabilityAggregationRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const department = requestParameters?.department;
    if (department === null || department === undefined) {
      throw new Error('Required parameter department was null or undefined when calling queryAggregatedDepartmentAvailabilitiesAvailabilityAggregation.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling queryAggregatedDepartmentAvailabilitiesAvailabilityAggregation.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling queryAggregatedDepartmentAvailabilitiesAvailabilityAggregation.');
    }
    const headcountDisplayType = requestParameters?.headcountDisplayType;
    if (headcountDisplayType === null || headcountDisplayType === undefined) {
      throw new Error('Required parameter headcountDisplayType was null or undefined when calling queryAggregatedDepartmentAvailabilitiesAvailabilityAggregation.');
    }
    const showEmployees = requestParameters?.showEmployees;
    const showNewRequests = requestParameters?.showNewRequests;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (department !== undefined && department !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>department, 'Department');
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (showEmployees !== undefined && showEmployees !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>showEmployees, 'ShowEmployees');
    }
    if (showNewRequests !== undefined && showNewRequests !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>showNewRequests, 'ShowNewRequests');
    }
    if (headcountDisplayType !== undefined && headcountDisplayType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>headcountDisplayType, 'HeadcountDisplayType');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/headcount/customer/overview/availability-aggregation/departments`;
    return this.httpClient.request<SharedPageModelOfHeadcountOverviewDepartmentsDepartmentModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryAggregatedTeamMemberAvailabilitiesAvailabilityAggregation(
    requestParameters?: HeadcountOverviewServiceQueryAggregatedTeamMemberAvailabilitiesAvailabilityAggregationRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfHeadcountOverviewTeamMemberAvailabilityAggregationsTeamMemberAvailabilityAggregationModel>;
  public queryAggregatedTeamMemberAvailabilitiesAvailabilityAggregation(
    requestParameters?: HeadcountOverviewServiceQueryAggregatedTeamMemberAvailabilitiesAvailabilityAggregationRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfHeadcountOverviewTeamMemberAvailabilityAggregationsTeamMemberAvailabilityAggregationModel>>;
  public queryAggregatedTeamMemberAvailabilitiesAvailabilityAggregation(
    requestParameters?: HeadcountOverviewServiceQueryAggregatedTeamMemberAvailabilitiesAvailabilityAggregationRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfHeadcountOverviewTeamMemberAvailabilityAggregationsTeamMemberAvailabilityAggregationModel>>;
  public queryAggregatedTeamMemberAvailabilitiesAvailabilityAggregation(
    requestParameters?: HeadcountOverviewServiceQueryAggregatedTeamMemberAvailabilitiesAvailabilityAggregationRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling queryAggregatedTeamMemberAvailabilitiesAvailabilityAggregation.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling queryAggregatedTeamMemberAvailabilitiesAvailabilityAggregation.');
    }
    const departments = requestParameters?.departments;
    const showEmployees = requestParameters?.showEmployees;
    const showNewRequests = requestParameters?.showNewRequests;
    const isAnalyticsView = requestParameters?.isAnalyticsView;
    const priorityEmployeeId = requestParameters?.priorityEmployeeId;
    const priorityPositionRequestId = requestParameters?.priorityPositionRequestId;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgCodeStartsWith = requestParameters?.orgCodeStartsWith;
    const businessArea = requestParameters?.businessArea;
    const region = requestParameters?.region;
    const country = requestParameters?.country;
    const location = requestParameters?.location;
    const countryCostType = requestParameters?.countryCostType;
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const orderBy = requestParameters?.orderBy;
    const property = requestParameters?.property;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (departments) {
      departments.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Departments');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (showEmployees !== undefined && showEmployees !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>showEmployees, 'ShowEmployees');
    }
    if (showNewRequests !== undefined && showNewRequests !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>showNewRequests, 'ShowNewRequests');
    }
    if (isAnalyticsView !== undefined && isAnalyticsView !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isAnalyticsView, 'IsAnalyticsView');
    }
    if (priorityEmployeeId !== undefined && priorityEmployeeId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>priorityEmployeeId, 'PriorityEmployeeId');
    }
    if (priorityPositionRequestId !== undefined && priorityPositionRequestId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>priorityPositionRequestId, 'PriorityPositionRequestId');
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgCodeStartsWith !== undefined && orgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCodeStartsWith, 'OrgCodeStartsWith');
    }
    if (businessArea) {
      businessArea.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'BusinessArea');
      });
    }
    if (region) {
      region.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Region');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (location) {
      location.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Location');
      });
    }
    if (countryCostType) {
      countryCostType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CountryCostType');
      });
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }
    if (orderBy !== undefined && orderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderBy, 'OrderBy');
    }
    if (property !== undefined && property !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>property, 'Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/headcount/customer/overview/availability-aggregation/team-members`;
    return this.httpClient.request<SharedPageModelOfHeadcountOverviewTeamMemberAvailabilityAggregationsTeamMemberAvailabilityAggregationModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryFieldValuesTeamMemberFieldValues(
    requestParameters?: HeadcountOverviewServiceQueryFieldValuesTeamMemberFieldValuesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedFieldValuesModel>;
  public queryFieldValuesTeamMemberFieldValues(
    requestParameters?: HeadcountOverviewServiceQueryFieldValuesTeamMemberFieldValuesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedFieldValuesModel>>;
  public queryFieldValuesTeamMemberFieldValues(
    requestParameters?: HeadcountOverviewServiceQueryFieldValuesTeamMemberFieldValuesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedFieldValuesModel>>;
  public queryFieldValuesTeamMemberFieldValues(
    requestParameters?: HeadcountOverviewServiceQueryFieldValuesTeamMemberFieldValuesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const field = requestParameters?.field;
    if (field === null || field === undefined) {
      throw new Error('Required parameter field was null or undefined when calling queryFieldValuesTeamMemberFieldValues.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling queryFieldValuesTeamMemberFieldValues.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling queryFieldValuesTeamMemberFieldValues.');
    }
    const search = requestParameters?.search;
    const departments = requestParameters?.departments;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgCodeStartsWith = requestParameters?.orgCodeStartsWith;
    const businessArea = requestParameters?.businessArea;
    const region = requestParameters?.region;
    const country = requestParameters?.country;
    const location = requestParameters?.location;
    const countryCostType = requestParameters?.countryCostType;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (search !== undefined && search !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search');
    }
    if (departments) {
      departments.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Departments');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgCodeStartsWith !== undefined && orgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCodeStartsWith, 'OrgCodeStartsWith');
    }
    if (businessArea) {
      businessArea.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'BusinessArea');
      });
    }
    if (region) {
      region.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Region');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (location) {
      location.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Location');
      });
    }
    if (countryCostType) {
      countryCostType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CountryCostType');
      });
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/headcount/customer/overview/team-member-field-values/${this.configuration.encodeParam({ name: 'field', value: field, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedFieldValuesModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryTeamMembersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel>;
  public queryTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryTeamMembersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel>>;
  public queryTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryTeamMembersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel>>;
  public queryTeamMembers(
    requestParameters?: HeadcountOverviewServiceQueryTeamMembersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling queryTeamMembers.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling queryTeamMembers.');
    }
    const search = requestParameters?.search;
    const departments = requestParameters?.departments;
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const orderBy = requestParameters?.orderBy;
    const property = requestParameters?.property;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (search !== undefined && search !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search');
    }
    if (departments) {
      departments.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Departments');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }
    if (orderBy !== undefined && orderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderBy, 'OrderBy');
    }
    if (property !== undefined && property !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>property, 'Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/headcount/customer/overview/team-members`;
    return this.httpClient.request<SharedPageModelOfHeadcountOverviewTeamMembersTeamMemberModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateManyTeamMemberAvailabilitiesTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateManyTeamMemberAvailabilitiesTeamMembersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>;
  public updateManyTeamMemberAvailabilitiesTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateManyTeamMemberAvailabilitiesTeamMembersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public updateManyTeamMemberAvailabilitiesTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateManyTeamMemberAvailabilitiesTeamMembersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public updateManyTeamMemberAvailabilitiesTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateManyTeamMemberAvailabilitiesTeamMembersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateManyTeamMemberAvailabilitiesTeamMembers.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling updateManyTeamMemberAvailabilitiesTeamMembers.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling updateManyTeamMemberAvailabilitiesTeamMembers.');
    }
    const headcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel = requestParameters?.headcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/headcount/customer/overview/team-members/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/availabilities`;
    return this.httpClient.request<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: headcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTeamMemberAvailabilityTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateTeamMemberAvailabilityTeamMembersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>;
  public updateTeamMemberAvailabilityTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateTeamMemberAvailabilityTeamMembersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public updateTeamMemberAvailabilityTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateTeamMemberAvailabilityTeamMembersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>>;
  public updateTeamMemberAvailabilityTeamMembers(
    requestParameters?: HeadcountOverviewServiceUpdateTeamMemberAvailabilityTeamMembersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateTeamMemberAvailabilityTeamMembers.');
    }
    const startDate = requestParameters?.startDate;
    if (startDate === null || startDate === undefined) {
      throw new Error('Required parameter startDate was null or undefined when calling updateTeamMemberAvailabilityTeamMembers.');
    }
    const endDate = requestParameters?.endDate;
    if (endDate === null || endDate === undefined) {
      throw new Error('Required parameter endDate was null or undefined when calling updateTeamMemberAvailabilityTeamMembers.');
    }
    const headcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel = requestParameters?.headcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/headcount/customer/overview/team-members/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/availabilities`;
    return this.httpClient.request<HeadcountOverviewDetailedTeamMembersTeamMemberDetailsModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: headcountOverviewDetailedTeamMembersUpdateTeamMemberAvailabilityModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
