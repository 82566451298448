/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncentiveRecordsAdjustRecordLetterStateModel } from './incentive-records-adjust-record-letter-state-model';

export interface IncentiveRecordsUpdateManyRecordStatesPartnerModel {
  state?: IncentiveRecordsUpdateManyRecordStatesPartnerModelStateEnum | null;
  processState?: IncentiveRecordsUpdateManyRecordStatesPartnerModelProcessStateEnum | null;
  recordLetterState?: IncentiveRecordsAdjustRecordLetterStateModel;
  recordIds?: Array<string> | null;
}
export enum IncentiveRecordsUpdateManyRecordStatesPartnerModelStateEnum {
  Active = 'Active',
  Draft = 'Draft',
  Outdated = 'Outdated',
  Irrelevant = 'Irrelevant'
}
export enum IncentiveRecordsUpdateManyRecordStatesPartnerModelProcessStateEnum {
  None = 'None',
  Open = 'Open',
  TafCreated = 'TafCreated',
  TafPublished = 'TafPublished',
  ReadyForCalculation = 'ReadyForCalculation',
  Calculated = 'Calculated',
  Frozen = 'Frozen',
  AchievementLetterCreated = 'AchievementLetterCreated',
  AchievementLetterPublished = 'AchievementLetterPublished'
}
